
import React, { useEffect, useState } from 'react'
import { Grid, Layout, Menu } from 'antd';
import Language from '../../Language';
import {ReactComponent as Logo} from "../../../resources/images/header-icon/logo-header.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import {isMobile} from 'react-device-detect';

const { Header } = Layout;
const { useBreakpoint } = Grid;

function HeaderComponent(props:any) {
    const [selectedKeys, setSelectedKeys] = useState<string[]>();
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(!!props?.isOpenDefault);
    const navigate = useNavigate();
    const location = useLocation();
    const screens = useBreakpoint();
    const menuItems = [
        {
            key: 'home',
            label: <Link to="/">Trang chủ</Link>,
            onClick: () => { setIsOpenMenu(false); props?.setIsOpenDefault(false);},
        },
        {
            key: 'product',
            label: 'Sản phẩm',
            children: [
                {
                    key: 'eLock',
                    label: <Link to="https://elock.nason.vn">E-Lock</Link>,
                    onClick: () => { setIsOpenMenu(false); props?.setIsOpenDefault(false);},
                }, 
                {
                    key: 'sourcing',
                    label: <Link to="https://erp.nason.vn">Sourcing</Link>,
                    onClick: () => { setIsOpenMenu(false); props?.setIsOpenDefault(false);},
                }
            ]
        },
        // {
        //     key: 'customer',
        //     label: <Link to="/customer">Khách hàng</Link>,
        //     onClick: () => { setIsOpenMenu(false); props?.setIsOpenDefault(false);},
        // },
        {
            key: 'company',
            label: <Link to="/company">Công ty</Link>,
            onClick: () => { setIsOpenMenu(false); props?.setIsOpenDefault(false);},
        },
        {
            key: 'contact',
            label: <Link to="/contact">Liên hệ</Link>,
            onClick: () => { setIsOpenMenu(false); props?.setIsOpenDefault(false);},
        },
    ];

    const onSelectMenu = (data:any)=>{
        setSelectedKeys([data?.key])
    }

    useEffect(()=>{
        setIsOpenMenu(!!props?.isOpenDefault);
    },[props?.isOpenDefault])

    useEffect(()=>{
        const listPath: string[] = location?.pathname?.split("/").filter((item:any)=>item!=='');
        if(listPath.length === 0){
            setSelectedKeys(["home"])
        }else if(listPath.length > 1){
            setSelectedKeys([listPath[listPath.length - 1]])
        }else{
            setSelectedKeys([listPath[0]])
        }
    },[location])


    return (
        <Header className={`nason-header ${(screens.md && !isMobile) ? '' : 'nason-header-mobile'}`}>
            <div className={`header-wrapper ${(screens.md && !isMobile) ? '' : 'header-mobile-layout'}`}>
                <div className="logo-header" onClick={()=> navigate("/")}>
                    <Logo/>
                </div>
                {
                    (screens.md && !isMobile) ? <div className="content-header">
                    <Menu 
                        className="menu-header"
                        theme="dark"
                        mode="horizontal"
                        selectedKeys={selectedKeys}
                        items={menuItems}
                        onSelect={onSelectMenu}
                    />
                    <Language />
                </div> : <>
                    <MenuOutlined className="menu-mobile-icon" onClick={()=> { props?.setIsOpenDefault(true); setIsOpenMenu(true)}}/>
                    <div className={`menu-mobile-container ${isOpenMenu ? "active" : ""}`}>
                        <div className="menu-mobile-content">
                            <CloseOutlined className="menu-mobile-icon" onClick={()=> { props?.setIsOpenDefault(false); setIsOpenMenu(false)}}/>
                            <Menu 
                                className="menu-header-mobile"
                                mode="inline"
                                selectedKeys={selectedKeys}
                                items={menuItems}
                                onSelect={onSelectMenu}
                            />
                        </div>
                    </div>  
                </>
                }
                
            </div>
        </Header>
    )
}

export default HeaderComponent