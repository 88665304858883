import React, { useRef, useState } from 'react'
import bgImg from "../../../resources/images/dashboard/bg8.png";
import { Col, Row, notification } from 'antd';
import CommonButton from '../../../components/Common/Button';
import emailjs from '@emailjs/browser';
import { Grid } from 'antd';
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;


function Section8() {
    const screens = useBreakpoint();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const form = useRef<any>();

    const sendEmail = (e:any) => {
        e.preventDefault();
        setIsLoading(true);
        emailjs.sendForm('service_4oyimy8', 'template_3ozo27u', form.current, 'fude_8uOF1LXFRWEL') // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
        .then((result) => {
            console.log(result.text);
            notification.success({
                message: "Đăng ký tư vấn thành công!",
            });
        }, (error) => {
            notification.error({
                message: "Có lỗi xảy ra! Vui lòng liên hệ hotline để dược tư vấn.",
            });
            console.log(error.text);
        }).finally(()=>{
            setIsLoading(false);
            form.current.reset();
        });

    };


    return (
        (screens.md && !isMobile) ? 
            <div id="contact" className='section-wrapper' style={{backgroundImage: `url(${bgImg})`}}>
            <div className="left-info"></div>
            <div className="right-info section8">
                <div className="form-wrapper">
                    <h2 className="form-title">Đăng ký liên hệ tư vấn</h2>
                    <form ref={form} onSubmit={sendEmail} className="form-custom">
                        <Row gutter={20}>
                            <Col span={12}>
                                <input placeholder="Nhập tên của bạn" maxLength={150} name="name" required className="avic-input " type="text"></input>
                            </Col>
                            <Col span={12}>
                                <input placeholder="Nhập email của bạn" maxLength={150} name="email" required className="avic-input " type="email"></input>
                            </Col>
                        </Row>
                        <input placeholder="Nhập số điện thoại của bạn" maxLength={150} name="phoneNumber" required className="avic-input " type="text"></input>
                        <input placeholder="Nhập địa chỉ của bạn" maxLength={150} name="address" required className="avic-input " type="text"></input>
                        <textarea placeholder="Nhập chủ đề" rows={3} name="topic" required className="avic-input avic-text-area " style={{resize: "none"}}></textarea>
                        <textarea placeholder="Nhập tin nhắn của bạn ở đây" rows={6} name="message" required className="avic-input avic-text-area " style={{resize: "none"}}></textarea>
                        <CommonButton
                            htmlType="submit"
                            size={'small'}
                            trueWidth={true}
                            className="btn-submit"
                            loading={isLoading}
                        >
                            Đăng ký
                        </CommonButton>
                    </form>
                </div>
            </div>
        </div> : <div id="contact" className='section-mobile-layout section8' style={{backgroundImage: `url(${bgImg})`}}>
            <div className="form-wrapper">
                <h2 className="form-title">Đăng ký liên hệ tư vấn</h2>
                <form ref={form} onSubmit={sendEmail} className="form-custom">
                    <Row gutter={20}>
                        <Col span={12}>
                            <input placeholder="Nhập tên của bạn" maxLength={150} name="name" required className="avic-input " type="text"></input>
                        </Col>
                        <Col span={12}>
                            <input placeholder="Nhập email của bạn" maxLength={150} name="email" required className="avic-input " type="email"></input>
                        </Col>
                    </Row>
                    <input placeholder="Nhập số điện thoại của bạn" maxLength={150} name="phoneNumber" required className="avic-input " type="text"></input>
                    <input placeholder="Nhập địa chỉ của bạn" maxLength={150} name="address" required className="avic-input " type="text"></input>
                    <textarea placeholder="Nhập chủ đề" rows={3} name="topic" required className="avic-input avic-text-area " style={{resize: "none"}}></textarea>
                    <textarea placeholder="Nhập tin nhắn của bạn ở đây" rows={6} name="message" required className="avic-input avic-text-area " style={{resize: "none"}}></textarea>
                    <CommonButton
                        htmlType="submit"
                        size={'small'}
                        trueWidth={true}
                        className="btn-submit"
                        loading={isLoading}
                    >
                        Đăng ký
                    </CommonButton>
                </form>
            </div>
        </div>
    )
}

export default Section8