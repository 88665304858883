import React, { useEffect } from 'react'
import { Grid, Result } from 'antd';
import CommonButton from './../../components/Common/Button';
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;

function Page404() {
    const screens = useBreakpoint();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="App">
            <Result
                status="404"
                title="404"
                subTitle="Xin lỗi, trang bạn truy cập không tồn tại."
                extra={
                    <CommonButton
                        btnType="primary"
                        onClick={() => {
                            window.location.href = '/'
                        }}
                        className="btn-404"
                        style={(screens.md && !isMobile) ? {}:{transform: "scale(2)"}}
                    >
                        Về trang chủ
                    </CommonButton>
                }
            />
        </div>
    );
}

export default Page404;

