import React from 'react'
import CommonButton from './../../components/Common/Button';
import background from "../../resources/images/company/background.png";
import { Grid } from 'antd';
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;

function Company() {
  const screens = useBreakpoint();
  return (
    (screens.md && !isMobile) ? 
      <div className='main-container'>
          <div className='section-wrapper' style={{backgroundImage: `url(${background})`}}>
              <div className='left-info left-info-company'>
                  <CommonButton>VỀ CHÚNG TÔI</CommonButton>
                  <h3>Công ty cổ phần công nghệ NASON Việt Nam</h3>
                  <p>Công ty chúng tôi là một công ty phần mềm chuyên về giải pháp liên quan đến nhà máy. Với kinh nghiệm và hiểu biết sâu sắc về ngành công nghiệp nhà máy, chúng tôi tập trung vào việc phát triển các giải pháp thông minh và đáng tin cậy để tối ưu hóa hoạt động của nhà máy, nâng cao hiệu suất và quản lý dữ liệu một cách hiệu quả.</p>
              </div>
          </div>
      </div> : <div className='main-container'>
          <div className='section-mobile-layout company' style={{backgroundImage: `url(${background})`}}>
            <CommonButton>VỀ CHÚNG TÔI</CommonButton>
            <h3>Công ty cổ phần công nghệ NASON Việt Nam</h3>
            <p>Công ty chúng tôi là một công ty phần mềm chuyên về giải pháp liên quan đến nhà máy. Với kinh nghiệm và hiểu biết sâu sắc về ngành công nghiệp nhà máy, chúng tôi tập trung vào việc phát triển các giải pháp thông minh và đáng tin cậy để tối ưu hóa hoạt động của nhà máy, nâng cao hiệu suất và quản lý dữ liệu một cách hiệu quả.</p>
        </div>
      </div>
  )
}

export default Company