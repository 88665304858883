import React from 'react'
import CommonButton from './../../../components/Common/Button';
import bgImg from "../../../resources/images/dashboard/bg1.png";
import rightImg from "../../../resources/images/dashboard/img1.png";
import { Grid } from 'antd';
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;

function Section1() {
    const screens = useBreakpoint();
    return (
        (screens.md && !isMobile) ? 
            <div className='section-wrapper' style={{backgroundImage: `url(${bgImg})`}}>
                <div className="left-info">
                    <h1>NASON TECHNOLOGY</h1>
                    <h1>VIETNAM JOINT STOCK COMPANY</h1>
                    <p>Giải pháp phần mềm hàng đầu Việt Nam</p>
                    <a href="#contact"><CommonButton>Liên hệ tư vấn</CommonButton></a>
                </div>
                <div className="right-info">
                    <img src={rightImg} alt="img1" />
                </div>
            </div> : <div className='section-mobile-layout section1' style={{backgroundImage: `url(${bgImg})`}}>
                    <div>
                        <h1>NASON TECHNOLOGY</h1>
                        <h1>VIETNAM JOINT STOCK COMPANY</h1>
                        <p>Giải pháp phần mềm hàng đầu Việt Nam</p>
                        <a href="#contact"><CommonButton>Liên hệ tư vấn</CommonButton></a>
                    </div>
                    <img src={rightImg} alt="img1" />
            </div>
        
    )
}

export default Section1