import React from 'react';
import { Col, Divider, Grid, Layout, Row } from 'antd';

import imageZalo from "../../../resources/images/footer-icon/zalo.png";
import imageTele from "../../../resources/images/footer-icon/telegram.png";
import imageSkype from "../../../resources/images/footer-icon/skype.png";
import {isMobile} from 'react-device-detect';

const { Footer } = Layout;
const { useBreakpoint } = Grid;

function FooterComponent() {
    const screens = useBreakpoint();

    return (
        <Footer className={`nason-footer  ${(screens.md && !isMobile) ? "" : "nason-footer-mobile"}`}>
            <div className={`footer-content-top`}>
                <div className='content-item'>
                    <h2>LIÊN HỆ <br /> CÔNG TY CỔ PHẦN CÔNG NGHỆ NASON VIỆT NAM</h2>
                    <ul className='list-item'>
                        <li>
                            <Row>
                                <Col span={5}>EMAIL</Col>
                                <Col span={18} style={(screens.md && !isMobile) ? {} :{textAlign: "left"}}><a href="mailto:info@nason.vn">info@nason.vn</a></Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col span={5}>HOTLINE</Col>
                                <Col span={18}  style={(screens.md && !isMobile) ? {} :{textAlign: "left"}}>0365264666</Col>
                            </Row>
                        </li>
                        <li>
                            <Row>
                                <Col span={5}>TRỤ SỞ</Col>
                                <Col span={18}  style={(screens.md && !isMobile) ? {} :{textAlign: "left"}}>Tầng 4, Tòa T608A, đường Tôn Quang Phiệt, phường Cổ Nhuế, quận Bắc Từ Liêm, Hà Nội</Col>
                            </Row>
                        </li>
                    </ul>
                    <div className="list-social-icon">
                        <a href='https://zalo.me/nasontechnology' target="_blank" rel="noreferrer"><div style={{backgroundImage: `url(${imageZalo})`}}></div></a>
                        <a href='https://t.me/nasontechnology' target="_blank" rel="noreferrer"><div style={{backgroundImage: `url(${imageTele})`}}></div></a>
                        <a href='https://join.skype.com/invite/sVaZDugveNaJ' target="_blank" rel="noreferrer"><div style={{backgroundImage: `url(${imageSkype})`}}></div></a>
                    </div>
                </div>
                <div className='content-item'>
                    <h2>CÔNG TY <br /></h2>
                    <ul className='list-item'>
                        <li>Giới thiệu</li>
                        <li>Khách hàng</li>
                        <li>Điều khoản</li>
                        <li>Privacy policy</li>
                    </ul>
                </div>
                <div className='content-item'>
                    <h2>SẢN PHẨM <br /></h2>
                    <ul className='list-item'>
                        <li>Sourcing</li>
                        <li>Hệ thống E-Lock</li>
                    </ul>
                </div>
            </div>
            <Divider />
            <div className='footer-content-bottom'>
                NASON © 2023 All Rights Reserved
            </div>
        </Footer>
    )
}

export default FooterComponent