import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Layout from "./components/Layout";

import Dashboard from './containers/Dashboard';
import Page404 from './containers/Page404';
import Company from './containers/Company';
import Customer from './containers/Customer';
import Contact from './containers/Contact';



const MyRoutes = () => {

    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<Dashboard />}/>
                    <Route path="/customer" element={<Customer />}/>
                    <Route path="/contact" element={<Contact />}/>
                    <Route path="/company" element={<Company />}/>
                    <Route path='*' element={<Page404/>} />
                </Routes>
            </Layout>
        </Router>
    );
}
export default MyRoutes
