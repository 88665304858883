import ViIcon from "../resources/images/language-icon/vietnamese.svg";
import UkIcon from "../resources/images/language-icon/english.svg";

export const DEFAULT_TIMEZONE = {
    cityName: "Hà Nội",
    contryCode: "VN",
    countryName: "Vietnam",
    gmtOffset: "UTC/GMT +07:00",
    id: 59,
    lat: 21.0283,
    lon: 105.8542,
    timeZone: "Asia/Ho_Chi_Minh",
}

export const LANGUAGE_LIST = [
    {
        value: 'vi',
        label: 'Tiếng Việt',
        icon: ViIcon
    },
    {
        value: 'en',
        label: 'English',
        icon: UkIcon
    },
]
export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY, HH:mm';
export const DATE_TIME_FORMAT_SECOND = 'DD/MM/YYYY, HH:mm:ss';
export const DOB_FORMAT = 'DD MMMM, YYYY';
export const DATE_TIME_FORMAT_2 = 'DD/MM, HH:mm';

export const PAGE_SIZE_LIST = [10, 20, 50, 100]
export const DEFAULT_PAGE_SIZE = 10
// export const REGEX_PASSWORD = /(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Za-z]).*/g
export const REGEX_USERNAME = /^([a-zA-Z0-9_]+)$/g
export const REGEX_CODE = /^([a-zA-Z0-9]+)$/g
export const REGEX_WITHOUT_SPECIAL_CHAR = /^([a-zA-Z0-9ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+)$/g
export const REGEX_PHONE_NUMBER = /^[0-9]([0-9]+){9}$/g
export const REGEX_EMAIL= /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g
export const REGEX_ONLY_NUMBER = /^[0-9]+$/g

export const AT_LEAST_8_CHARS = /^.{8,}$/;    // Ít nhất 8 kí tự
export const AT_LEAST_1_UPPERCASE = /^(?=.*[A-Z]).*$/;    // Ít nhất 1 kí tự viết hoa
export const AT_LEAST_1_NUMBER = /^(?=.*\d).*$/;  // Ít nhất 1 số
export const REGEX_PASSWORD = /^(?=.*[A-Z])(?=.*\d).{8,}$/;


export const VI_MOMENT_CONFIG = {
    week: {
        dow: 1 /// Date offset
    },
    months: [
        'Tháng 1',
        'Tháng 2',
        'Tháng 3',
        'Tháng 4',
        'Tháng 5',
        'Tháng 6',
        'Tháng 7',
        'Tháng 8',
        'Tháng 9',
        'Tháng 10',
        'Tháng 11',
        'Tháng 12',
    ],
    monthsShort: [
        'Thg 1',
        'Thg 2',
        'Thg 3',
        'Thg 4',
        'Thg 5',
        'Thg 6',
        'Thg 7',
        'Thg 8',
        'Thg 9',
        'Thg 10',
        'Thg 11',
        'Thg 12',
    ],
    weekdays: ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'],
    weekdaysShort: ['CN', 'Th 2', 'Th 3', 'Th 4', 'Th 5', 'Th 6', 'Th 7'],
    weekdaysMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
    relativeTime: {
        future: 'trong %s',
        past: '%s trước',
        s: 'vài giây trước',
        ss: '%d giây',
        m: '1 phút',
        mm: '%d phút',
        h: '1 giờ',
        hh: '%d giờ',
        d: '1 ngày',
        dd: '%d ngày',
        w: '1 tuần',
        ww: '%d tuần',
        M: '1 tháng',
        MM: '%d tháng',
        y: '1 năm',
        yy: '%d năm',
    },
}
