import React from 'react'
import bgImg from "../../../resources/images/dashboard/bg5.png";
import { Grid } from 'antd';
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;

function Section5() {
    const screens = useBreakpoint();
    return (
        (screens.md && !isMobile) ? 
            <div className='section-wrapper' style={{backgroundImage: `url(${bgImg})`}}>
                <div className="left-info"></div>
                <div className="right-info section5">
                    <h1>Hệ thống quản lý E-Lock</h1>
                    <p className="text-right">Một hệ thống quản trị quy trình trong nhà máy được phát triển 
                        <br /> bởi đội ngũ NASON, sử dụng thiết bị khóa 
                        <br /> E-Lock nhằm bảo vệ hàng hóa, đồng thời giúp cho quy trình 
                        <br /> trong nhà máy trở nên dễ dàng và thuận tiện hơn…
                    </p>
                    <div className="detail-btn"><a href="https://elock.nason.vn">Xem chi tiết</a></div>
                </div>
            </div> : <div className='section-mobile-layout section5' style={{backgroundImage: `url(${bgImg})`}}>
                    <h1>Hệ thống quản lý E-Lock</h1>
                    <p >Một hệ thống quản trị quy trình trong nhà máy được phát triển 
                        <br /> bởi đội ngũ NASON, sử dụng thiết bị khóa 
                        <br /> E-Lock nhằm bảo vệ hàng hóa, đồng thời giúp cho quy trình 
                        <br /> trong nhà máy trở nên dễ dàng và thuận tiện hơn…
                    </p>
                    <div className="detail-btn"><a href="https://elock.nason.vn">Xem chi tiết</a></div>
            </div>
    )
}

export default Section5