import React from 'react'
import bgTmg from "../../../resources/images/dashboard/bg3.png";
import rightImg from "../../../resources/images/dashboard/img3.png";
import { Grid } from 'antd';
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;

function Section3() {
    const screens = useBreakpoint();
    return (
        (screens.md && !isMobile) ? <div className='section-wrapper' style={{backgroundImage: `url(${bgTmg})`}}>
            <div className="left-info">
                <h2>Sourcing</h2>
                <div className="description">Giải pháp công nghệ được thiết kế để hợp lý hóa và tối ưu hóa các quy trình tìm nguồn cung ứng và mua sắm trong các tổ chức. Nó cung cấp các công cụ và tính năng để tự động hóa các nhiệm vụ khác nhau liên quan đến tìm nguồn cung ứng nhà cung cấp, quản lý và phân tích dữ liệu mua sắm.</div>
                <div className="detail-btn"><a href="https://erp.nason.vn">Xem chi tiết</a></div>
            </div>
            <div className="right-info">
                <img src={rightImg} alt="img3" />
            </div>
        </div> :  <div className='section-mobile-layout section3' style={{backgroundImage: `url(${bgTmg})`}}>
            <div>
                <h2>Sourcing</h2>
                <div className="description">Giải pháp công nghệ được thiết kế để hợp lý hóa và tối ưu hóa các quy trình tìm nguồn cung ứng và mua sắm trong các tổ chức. Nó cung cấp các công cụ và tính năng để tự động hóa các nhiệm vụ khác nhau liên quan đến tìm nguồn cung ứng nhà cung cấp, quản lý và phân tích dữ liệu mua sắm.</div>
                <div className="detail-btn"><a href="https://erp.nason.vn">Xem chi tiết</a></div>
            </div>
            <img src={rightImg} alt="img3" />
        </div>
    )
}

export default Section3