import { Grid, Layout } from 'antd';
import React, {ReactNode, useEffect, useState} from 'react';
import HeaderComponent from './components/Header';
import FooterComponent from './components/Footer';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ScrollTopIcon } from "../../resources/images/dashboard/scroll-top.svg";
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;
const { Content } = Layout;

function DashboardLayout(props:{children: ReactNode}) {
    const [visible, setVisible] = useState<boolean>(false);
    const screens = useBreakpoint();
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [pathname]);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > window.innerHeight){
            setVisible(true)
        } 
        else if (scrolled <= window.innerHeight){
            setVisible(false)
        }
    };
    
    window.addEventListener('scroll', toggleVisible);

    //detect vuốt ngang để show menu
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [isOpenDefault, setIsOpenDefault] = useState<boolean>(false);

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 150 

    const onTouchStart = (e : any) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e : any) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe || isRightSwipe) {
        console.log('swipe', isLeftSwipe ? 'left' : 'right')
        if(isLeftSwipe) {
            setIsOpenDefault(true);
        }else{
            setIsOpenDefault(false);
        }
    }
        
    setTouchStart(null)
    setTouchEnd(null)
    // add your conditional logic here
    }

    return (
        <Layout className={'nason-layout'} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            <HeaderComponent isOpenDefault={isOpenDefault} setIsOpenDefault={setIsOpenDefault}/>
            <Content>
                <div className="site-layout-background">
                    {props.children}
                </div>
            </Content>
            <FooterComponent/>
            <ScrollTopIcon 
                className={`scroll-top-btn ${(screens.md && !isMobile) ? "" : "scroll-top-btn-mobile"}`} 
                onClick={()=> window.scrollTo({top: 0, left: 0, behavior: "smooth"})}
                style={{display: visible ? 'inline' : 'none'}}
            />
        </Layout>
    );
}

export default DashboardLayout;

