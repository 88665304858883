import {ConfigProvider} from "antd";
import Routes from './Routes';
import moment from 'moment';
import viVN from 'antd/es/locale/vi_VN';
import enUS from 'antd/es/locale/en_US';
import LocalStorage from "./utils/localStorage";
import {LANGUAGE_LIST, VI_MOMENT_CONFIG} from "./utils/constants";

const localLanguage = LocalStorage.getInstance().read('language') || LANGUAGE_LIST[0]?.value;

let locale = viVN
switch (localLanguage) {
    case LANGUAGE_LIST[1]?.value:
        locale = enUS
        break;
    default:
        moment.locale('vi', VI_MOMENT_CONFIG);
        locale = viVN
        break;
}


function App() {
    return (
        <ConfigProvider locale={locale}>
            {/* {
                isLoading ? <CommonSpin isLoading={true} /> : <Routes/>
            } */}
            <Routes/>
        </ConfigProvider>
    );
}

export default App;
