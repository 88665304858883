import React from 'react';
// import rightImg from "../../resources/images/contact/contact-image.png"
import imageZalo from "../../resources/images/footer-icon/zalo.png";
import imageTele from "../../resources/images/footer-icon/telegram.png";
import imageSkype from "../../resources/images/footer-icon/skype.png";
import BannerImage from './Components/BannerImage';
import { Grid } from 'antd';
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;

function Contact() {
    const screens = useBreakpoint();
    return <div className='main-container'>
        {
            (screens.md && !isMobile) ? <div className='section-wrapper contact-wrapper'>
            <div className="left-info">
                <div className="contact-block">
                    <h1 className="contact-title">
                        CÔNG TY CỔ PHẦN CÔNG NGHỆ <span style={{color:"#FA8A07"}}>NASON</span> VIỆT NAM
                    </h1>
                    <div className="contact-info">Thông tin liên hệ</div>
                    <div className="contact-detail">
                        <div><span style={{fontWeight: 700}}>EMAIL</span> : <span style={{textDecoration:"underline"}}><a style={{color: "#000000"}} href="mailto:info@nason.vn">info@nason.vn</a></span></div>
                        <div><span style={{fontWeight: 700}}>HOTLINE</span> : 0365264666</div>
                        <div><span style={{fontWeight: 700}}>TRỤ SỞ</span> : Tầng 4 - Tòa T608A, đường Tôn Quang Phiệt, phường Cổ Nhuế, quận Bắc Từ Liêm, Hà Nội</div>
                    </div>
                    <div className="contact-list-icon">
                    <a href='https://zalo.me/nasontechnology' target="_blank" rel="noreferrer"><div style={{backgroundImage: `url(${imageZalo})`}}></div></a>
                    <a href='https://t.me/nasontechnology' target="_blank" rel="noreferrer"><div style={{backgroundImage: `url(${imageTele})`}}></div></a>
                    <a href='https://join.skype.com/invite/sVaZDugveNaJ' target="_blank" rel="noreferrer"><div style={{backgroundImage: `url(${imageSkype})`}}></div></a>
                    </div>
                </div>
            </div>
            {/* <div className="right-info" style={{backgroundImage: `url(${rightImg})`}}> */}
            <div className="right-info" >
                <BannerImage/>
            </div>
        </div> :  <div className='section-mobile-layout contact-wrapper-mobile'>
                    <BannerImage/>
                    <div className="contact-block">
                        <h1 className="contact-title">
                            CÔNG TY CỔ PHẦN CÔNG NGHỆ <span style={{color:"#FA8A07"}}>NASON</span> VIỆT NAM
                        </h1>
                        <div className="contact-info">Thông tin liên hệ</div>
                        <div className="contact-detail">
                            <div><span style={{fontWeight: 700}}>EMAIL</span> : <span style={{textDecoration:"underline"}}><a style={{color: "#000000"}} href="mailto:info@nason.vn">info@nason.vn</a></span></div>
                            <div><span style={{fontWeight: 700}}>HOTLINE</span> : 0365264666</div>
                            <div><span style={{fontWeight: 700}}>TRỤ SỞ</span> : Tầng 4 - Tòa T608A, đường Tôn Quang Phiệt, phường Cổ Nhuế, quận Bắc Từ Liêm, Hà Nội</div>
                        </div>
                        <div className="contact-list-icon">
                        <a href='https://zalo.me/nasontechnology' target="_blank" rel="noreferrer"><div style={{backgroundImage: `url(${imageZalo})`}}></div></a>
                        <a href='https://t.me/nasontechnology' target="_blank" rel="noreferrer"><div style={{backgroundImage: `url(${imageTele})`}}></div></a>
                        <a href='https://join.skype.com/invite/sVaZDugveNaJ' target="_blank" rel="noreferrer"><div style={{backgroundImage: `url(${imageSkype})`}}></div></a>
                        </div>
                    </div>
            </div>
        }
            
        </div>
}

export default Contact