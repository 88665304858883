import React from 'react'
import customerImg from "../../resources/images/company/customer-img.png";
import { Grid } from 'antd';
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;


function Customer() {
    const screens = useBreakpoint();
    return  <div className='main-container' style={(screens.md && !isMobile) ? {}: {minHeight: 0}}>
            {(screens.md && !isMobile) ? 
                <div className='section-wrapper customer-wrapper'>
                    <div className='customer-title'>DOANH NGHIỆP ĐÃ ĐỒNG HÀNH CÙNG NASON </div>
                    <div className='customer-img'>
                        <img src={customerImg} alt="customer-img" />
                    </div>
                </div> : <div className='section-mobile-layout customer-wrapper-mobile'>
                    <div className='customer-title'>DOANH NGHIỆP ĐÃ ĐỒNG HÀNH CÙNG NASON </div>
                    <div className='customer-img'>
                        <img src={customerImg} alt="customer-img" />
                    </div>
                </div>
            }
        </div> 
}

export default Customer