import React from 'react'
import img6_1 from "../../../resources/images/dashboard/img6_1.png";
import img6_2 from "../../../resources/images/dashboard/img6_2.png";
import img6_3 from "../../../resources/images/dashboard/img6_3.png";
import { Grid } from 'antd';
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;

function Section6() {
    const screens = useBreakpoint();
    return (
        (screens.md && !isMobile) ? 
            <div className='section-wrapper'>
                <div className="center-info" style={{padding: "0 10%"}}>
                    <h2>Tăng tối đa kết quả kinh doanh</h2>
                    <div className="list-block">
                        <div className="list-block-item border-orange">
                            <img src={img6_1} alt="img6_1" className="list-block-item_icon icon_large" />
                            <div className="list-block-item_title">Bảo vệ xe hàng</div>
                            <div className="list-block-item_description">Ngăn chặn truy cập trái phép, là bằng chứng khi bị gỡ bỏ</div>
                        </div>
                        <div className="list-block-item border-orange">
                            <img src={img6_2} alt="img6_2" className="list-block-item_icon icon_large" />
                            <div className="list-block-item_title">Quản lý chuỗi cung ứng</div>
                            <div className="list-block-item_description">Theo dõi và giám sát sự di chuyển của hàng hóa, lưu giữ hồ sơ của hành trình</div>
                        </div>
                        <div className="list-block-item border-orange">
                            <img src={img6_3} alt="img6_3" className="list-block-item_icon icon_large" />
                            <div className="list-block-item_title">Bảo vệ hàng hóa giá trị</div>
                            <div className="list-block-item_description">Cung cấp một lớp bảo vệ khi di chuyển và lưu trữ</div>
                        </div>
                    </div>
                </div>
            </div> : <div className='section-mobile-layout section6'>
                    <h2>Tăng tối đa kết quả kinh doanh</h2>
                    <div className="list-block">
                        <div className="list-block-item border-orange">
                            <img src={img6_1} alt="img6_1" className="list-block-item_icon icon_large" />
                            <div className="list-block-item_title">Bảo vệ xe hàng</div>
                            <div className="list-block-item_description">Ngăn chặn truy cập trái phép, là bằng chứng khi bị gỡ bỏ</div>
                        </div>
                        <div className="list-block-item border-orange">
                            <img src={img6_2} alt="img6_2" className="list-block-item_icon icon_large" />
                            <div className="list-block-item_title">Quản lý chuỗi cung ứng</div>
                            <div className="list-block-item_description">Theo dõi và giám sát sự di chuyển của hàng hóa, lưu giữ hồ sơ của hành trình</div>
                        </div>
                        <div className="list-block-item border-orange">
                            <img src={img6_3} alt="img6_3" className="list-block-item_icon icon_large" />
                            <div className="list-block-item_title">Bảo vệ hàng hóa giá trị</div>
                            <div className="list-block-item_description">Cung cấp một lớp bảo vệ khi di chuyển và lưu trữ</div>
                        </div>
                    </div>
            </div>
    )
}

export default Section6