import React from 'react';
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
import Section5 from './components/Section5';
import Section6 from './components/Section6';
import Section8 from './components/Section8';
import Customer from '../Customer';
import { Grid } from 'antd';
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;

function Dashboard() {
    const screens = useBreakpoint();
    return <div className="main-container">
        <Section1/>
        <Section2/>
        <Section3/>
        <Section4/>
        <Section5/>
        <Section6/>
        {/* <Customer/> */}
        <Section8/>
        <div className={`map-wrapper ${(screens.md && !isMobile) ? "" : "map-mobile"}`}>
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1861.782572278663!2d105.78302649193566!3d21.0500787819745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313452e85205d6bb%3A0x616150545ee0c46e!2zQ8O0bmcgVHkgQ-G7lSBQaOG6p24gQ8O0bmcgTmdo4buHIEFURUMgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1690442789544!5m2!1svi!2s"
                style={{border:0, width: "100%", height: "100%"}} 
                allowFullScreen={true}
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                title="map"
            >
            </iframe>
        </div>
    </div>;
}

export default Dashboard;

