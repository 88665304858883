import React from 'react'
import img4 from "../../../resources/images/dashboard/img4.png";
import { Grid } from 'antd';
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;

function Section4() {
    const screens = useBreakpoint();
    return (
        (screens.md && !isMobile) ? <div className='section-wrapper'>
            <div className="center-info" style={{padding: "0 10%"}}>
                <h2>Tăng tối đa kết quả kinh doanh</h2>
                <div className="list-block">
                    <div className="list-block-item">
                        <img src={img4} alt="img4" className="list-block-item_icon" />
                        <div className="list-block-item_title">Quản lý hoạt động mua sắm</div>
                        <div className="list-block-item_description">Quản lý báo giá, danh mục và nhà cung cấp</div>
                    </div>
                    <div className="list-block-item">
                        <img src={img4} alt="img4" className="list-block-item_icon" />
                        <div className="list-block-item_title">Tối ưu hóa hoạt động mua sắm</div>
                        <div className="list-block-item_description">Đẩy nhanh quá trình mua sắm, xây dựng phương pháp tốt nhất và cộng tác dễ dàng với nhà cung cấp</div>
                    </div>
                    <div className="list-block-item">
                        <img src={img4} alt="img4" className="list-block-item_icon" />
                        <div className="list-block-item_title">Tiết kiệm</div>
                        <div className="list-block-item_description">Tăng lợi nhuận của bạn bằng cách tăng hiệu quả, sự nhanh nhẹn và độ chính xác</div>
                    </div>
                </div>
            </div>
        </div> : <div className='section-mobile-layout section4'> 
                <h2>Tăng tối đa kết quả kinh doanh</h2>
                <div className="list-block">
                    <div className="list-block-item">
                        <img src={img4} alt="img4" className="list-block-item_icon" />
                        <div className="list-block-item_title">Quản lý hoạt động mua sắm</div>
                        <div className="list-block-item_description">Quản lý báo giá, danh mục và nhà cung cấp</div>
                    </div>
                    <div className="list-block-item">
                        <img src={img4} alt="img4" className="list-block-item_icon" />
                        <div className="list-block-item_title">Tối ưu hóa hoạt động mua sắm</div>
                        <div className="list-block-item_description">Đẩy nhanh quá trình mua sắm, xây dựng phương pháp tốt nhất và cộng tác dễ dàng với nhà cung cấp</div>
                    </div>
                    <div className="list-block-item">
                        <img src={img4} alt="img4" className="list-block-item_icon" />
                        <div className="list-block-item_title">Tiết kiệm</div>
                        <div className="list-block-item_description">Tăng lợi nhuận của bạn bằng cách tăng hiệu quả, sự nhanh nhẹn và độ chính xác</div>
                    </div>
                </div>
        </div>
    )
}

export default Section4