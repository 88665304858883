import React from 'react'
import CommonButton from '../../../components/Common/Button';
import bgTmg from "../../../resources/images/dashboard/bg2.png";
import { Grid } from 'antd';
import {isMobile} from 'react-device-detect';

const { useBreakpoint } = Grid;

function Section2() {
    const screens = useBreakpoint();
    return (
        (screens.md && !isMobile) ? <div className='section-wrapper' style={{backgroundImage: `url(${bgTmg})`, backgroundAttachment: "local"}}>
            <div className="center-info">
                <CommonButton>Lời chào mừng</CommonButton>
                <h1>Công ty cổ phần công nghệ NASON Việt Nam</h1>
                <div className="div-section2">Đây là với niềm vui lớn được giới thiệu quý khách đến với chúng tôi, với mong muốn ứng dụng CNTT vào doanh nghiệp nhằm nâng cao quy trình quản lý tăng hiệu quả kinh doanh, giảm chi phí và tiết kiệm thời gian. Là đối tác tin cậy về các giải pháp phần mềm cho doanh nghiệp, chúng tôi tự tin rằng các dự án của NASON sẽ mang lại giá trị đặc biệt và mở ra những bước phát triển mới. Đừng bỏ lỡ cơ hội trở thành đối tác tin cậy của chúng tôi.</div>
            </div>
        </div> : <div className='section-mobile-layout section2' style={{backgroundImage: `url(${bgTmg})`, backgroundAttachment: "local"}}>
                <CommonButton>Lời chào mừng</CommonButton>
                <h1>Công ty cổ phần công nghệ NASON Việt Nam</h1>
                <div className="div-section2">Đây là với niềm vui lớn được giới thiệu quý khách đến với chúng tôi, với mong muốn ứng dụng CNTT vào doanh nghiệp nhằm nâng cao quy trình quản lý tăng hiệu quả kinh doanh, giảm chi phí và tiết kiệm thời gian. Là đối tác tin cậy về các giải pháp phần mềm cho doanh nghiệp, chúng tôi tự tin rằng các dự án của NASON sẽ mang lại giá trị đặc biệt và mở ra những bước phát triển mới. Đừng bỏ lỡ cơ hội trở thành đối tác tin cậy của chúng tôi.</div>
        </div>
    )
}

export default Section2